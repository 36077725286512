import { render, staticRenderFns } from "./InformationMenu.vue?vue&type=template&id=143160a8&scoped=true&"
import script from "./InformationMenu.vue?vue&type=script&lang=js&"
export * from "./InformationMenu.vue?vue&type=script&lang=js&"
import style0 from "./InformationMenu.vue?vue&type=style&index=0&id=143160a8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143160a8",
  null
  
)

export default component.exports