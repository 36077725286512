<template>
	<div class="empty_page">
		<div class="empty">
			<img class="icon" :src="icon"/>
			<span class="name">{{name}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		name:"empty-page",
		props:{
			name:{
				type:String,
				default:() => {
					return '暂无数据'
				}
			},
			icon:{
				type:String,
				default:() => {
					return require('../assets/images/data.png')
				}
			}
		},
		data() {
			return {
				
			};
		},
	}
</script>

<style lang="scss" scoped> 
.empty_page{
	width: 100%;
	height: 100%;
	.empty {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		 margin-top: 10vh;
		.icon{
			width: 320rpx;
			height: 320rpx;
		}
		.name {
			color:rgb(192, 196, 204);
			 font-size: 14px;
		}
	}
}
</style>