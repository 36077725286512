<!--
* @description 精彩视频
* @fileName excitingVideo.vue
* @author liulian
* @date 2023/07/25 14:20:58
-->
<template>
  <div class="excitingVideo">
    <div class="e_top">
      <div class="e_t_menu flex-row align-center">
        <div class="t_m_item cursor-pointer text-align-center font-size-16 font-weight-500" :class="activeIndex === item.key ? 't_m_item_active' : ''" v-for="(item, index) in types" :key="index" @click="clickHandle(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <template v-if="lists.length > 0">
      <div class="e_list flex-row flex-wrap">
        <div class="e_l_item cursor-pointer" v-for="(item, index) in lists" :key="index" @click="goDetail(item)">
          <img class="e_l_i_cover" :src="item.videoUrl" alt="封面图" />
          <div class="e_l_i_title clamp-2 font-size-12 font-weight-500">{{ item.videoTitle }}</div>
          <div class="e_l_i_time font-size-10 font-weight-400">{{ item.updateTime }}</div>
        </div>
      </div>
      <div class="z_m_pagination" v-if="total>24">
        <Pagination layout="pager, next" :isPadding="false" :page.sync="queryInfo.pageNum" :limit.sync="queryInfo.pageSize" :total="total" @pagination="handleSizeChange"
          :nextText="$t(`common.paginationNext`)"></Pagination>
      </div>
    </template>
    <template v-else>
      <!-- <emptyPage :name="$t(`common.NoData`)"/> -->
      <emptyPage :name="$t(`common.NoData`)"/>
    </template>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue"
import { clientVideoListPageApi } from "@/api/api"
import emptyPage from "@/components/EmptyPage.vue"

export default {
  name: "excitingVideo",
  components: {
    Pagination,
    emptyPage,
  },
  data() {
    return {
      types: [
        {
          name: "全　部",
          key:null,
        },
        {
          name: "数字化软件产品",
          key: 0,
        },
        {
          name: "数字建管系列产品",
          key: 1,
        },
        {
          name: "基建狂魔",
          key: 2,
        },
        {
          name: "工程知识",
          key: 3,
        },
        {
          name: "科技创新",
          key: 4,
        },
      ],
      activeIndex: null,
      lists: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        videoClass: null,
      },
      total: 0,
    }
  },
  computed: {},
  created() {
    // 获取存储的type类型
    let type = JSON.parse(sessionStorage.getItem('menuType'))
    if(type){
      this.activeIndex = type[0]
      this.queryInfo.videoClass = type[0]
    }else{
    }
    this.getInfo()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 跳转详情
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 15:14:25
     * @param: ''
     */
    goDetail(item) {
      // this.$router.push(`/zxyhdVideoDetail?type=information&id=${item.videoId}`)
      this.$uniFun.openUrl(`/dnnweb/zxyhdVideoDetail?type=information&id=${item.videoId}`)
      // 存贮当前tab的类型
      sessionStorage.setItem('menuType',JSON.stringify([this.queryInfo.videoClass]))
    },
    getInfo() {
      clientVideoListPageApi(this.queryInfo).then((res) => {
        if (res.code === 200) {
          this.lists = res.rows
          this.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /*
     * @Description: 点击类型
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 14:35:49
     * @param: ''
     */
    clickHandle(item) {
      this.activeIndex = item.key
      this.queryInfo.videoClass = item.key
      this.queryInfo.pageNum = 1
      this.getInfo()
    },
    /*
     * @Description: 切换分页
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 17:17:30
     * @param: ''
     */
    handleSizeChange(e) {
      this.queryInfo.pageNum = e.pageIndex
      this.getInfo()
    },
  },

  watch: {
    '$i18n.locale': function() {
      // 获取存储的type类型
      let type = JSON.parse(sessionStorage.getItem('menuType'))
      if(type){
        this.activeIndex = type[0]
        this.queryInfo.videoClass = type[0]
      }else{
      }
      this.getInfo()
    }
  }

}
</script>

<style scoped lang="scss">
.excitingVideo {
  width: 100%;
  background-color: rgb(248, 248, 248);
  .e_top {
    margin-bottom: 32px;
    .e_t_menu {
      margin-left: 20px;
      .t_m_item {
        min-width: 100px;
        height: 32px;
        line-height: 30px;
        border-radius: 16px;
        border: 1px solid #979797;
        color: rgba(29, 29, 31, 0.4);
        margin-right: 32px;
        white-space: nowrap;
        text-align: center;
        padding-left: 13px;
        padding-right: 16px;
      }
      .t_m_item_active {
        background: #faa639;
        border-radius: 16px;
        border: unset;
        color: #fff;
      }
    }
  }

  .e_list {
    .e_l_item {
      flex: 0 0 22.5%;
      margin-left: 22px;
      margin-bottom: 33px;
      .e_l_i_cover {
        width: 100%;
        height: 128px;
        border-radius: 4px;
        object-fit: cover;
      }
      .e_l_i_title {
        color: #333333;
        margin-top: 4px;
      }
      .e_l_i_time {
        color: #999999;
        margin-top: 4px;
      }
    }
  }
}
</style>
