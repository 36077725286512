<!--
* @description 资讯与活动
* @fileName index.vue
* @author liulian
* @date 2023/06/29 17:52:07
-->
<template>
  <div class="zxyhd_container">
    <div class="z_top" :class="menuActiveIndex === 'video' ? 'z_top1' : ''"></div>
    <div class="z_main flex-row">
      <!-- <div class="z_m-left">
        <div class="z_m_l_title">
          <span class="font-size-32 font-weight-500">最</span>
          <span class="font-weight-500 font-size-24">新资讯</span>
          <span class="z_m_l_t_logo font-size-32">News</span>
        </div>
        <div class="z_m_l_menus">
          <div class="z_m_l_m_item cursor-pointer flex-row align-center" v-for="(item, index) in menus" :key="index" @click="clickHandle(item, index)">
            <span class="z_m_l_m_i_dot" :class="menuActiveIndex === index ? 'z_m_l_m_i_d_yellow' : ''"></span>
            <span class="z_m_l_m_i_span font-size-18 font-weight-400 font-color-black-1D1D1F">{{ item.name }}</span>
          </div>
          <div class="z_m_l_line"></div>
        </div>
      </div> -->
      <InformationMenu v-if="menus.length > 0" :menuList="menus" @tab="clickHandle" />

      <!-- 新闻资讯-精彩视频 -->
      <div class="z_m_right" v-if="menuActiveIndex === 'video'">
        <excitingVideo />
      </div>
      <div class="z_m_right" v-else>
        <template v-if="lists.length > 0">
          <div class="z_m_r_item cursor-pointer flex-row-between" :class="index === lists.length - 1 ? 'z_m_r_item_last' : ''" v-for="(item, index) in lists" :key="index" @click="clickItem(item)">
            <div class="z_m_r_i_left" v-if="item.messageUrl">
              <img class="z_m_r_i_l_cover" :src="item.messageUrl" alt="新闻封面" />
            </div>
            <div class="z_m_r_i_center">
              <div class="z_m_r_i_r_title clamp-2 font-size-18 font-weight-500 font-color-black-1D1D1F">{{ item.messageTitle }}</div>
              <div class="z_m_r_i_r_p clamp-3 font-size-12 font-weight-400">{{ item.messageSynopsis }}</div>
            </div>
            <div class="z_m_r_i_right flex-column-center align-center">
              <span class="z_m_r_i_line"></span>
              <span class="z_m_r_i_r_top font-size-18 font-weight-400 font-color-black-1D1D1F">{{ $uniFun.formatDate(item.updateTime, "MM-dd") }}</span>
              <span class="z_m_r_i_r_bottom font-size-12 font-weight-400">{{ $uniFun.formatDate(item.updateTime, "yyyy") }}</span>
            </div>
          </div>
          <div class="z_m_pagination" v-if="total > 8">
            <Pagination layout="pager, next" :isPadding="false" :page.sync="queryInfo.pageNum" :limit.sync="queryInfo.pageSize" :total="total" @pagination="handleSizeChange"
              :nextText="$t(`common.paginationNext`)"></Pagination>
          </div>
        </template>
        <template v-else>
          <emptyPage></emptyPage>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue"
import InformationMenu from "@/components/InformationMenu.vue"
import excitingVideo from "@/views/web/information/excitingVideo.vue"
import emptyPage from "@/components/EmptyPage.vue"
import { clientMessageListPageApi } from "@/api/api"
export default {
  name: "index",
  components: {
    Pagination,
    InformationMenu,
    excitingVideo,
    emptyPage,
  },
  data() {
    return {
      menus: [
        {
          name: "menu_title1",
          key: 0,
        },
        {
          name: "menu_title2",
          key: 1,
        },
        {
          name: "menu_title3",
          key: 2,
        },
        {
          name: "menu_title4",
          key: 3,
        },
        {
          name: "menu_title5",
          key: "video",
        },
      ],
      lists: [],
      menuActiveIndex: "",
      total: 0,
      queryInfo: {
        pageSize: 8,
        pageNum: 1,
        messageClass: 0,
      },
    }
  },
  computed: {},
  created() {
    let queryIndex = this.$route.query.class
    this.menuActiveIndex = queryIndex
    if (this.menuActiveIndex === "video") {
    } else {
      
      // this.queryInfo.messageClass = this.menuActiveIndex
      this.queryInfo.messageClass = -1

      this.init()
    }
  },
  mounted() {},
  methods: {
    /*
     * @Description: 点击跳转新闻资讯详情
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 18:17:49
     * @param: ''
     */
    clickItem(item) {
      // this.$router.push(`/newsDetail?type=information&id=${item.messageId}`)
      this.$uniFun.openUrl(`/dnnweb/newsDetail?type=information&id=${item.messageId}`)
    },
    /*
     * @Description: 点击侧边栏
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 18:03:00
     * @param: ''
     */
    clickHandle(item, index) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          class: item.key,
        },
      })
      this.menuActiveIndex = item.key
      if (item.key !== "video") {
        this.queryInfo.messageClass = item.key
        this.queryInfo.pageNum = 1
        this.init()
      }
    },
    /*
     * @Description: 切换分页
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 17:17:30
     * @param: ''
     */
    handleSizeChange(e) {
      this.queryInfo.pageNum = e.pageIndex
      this.init()
    },
    init() {
      clientMessageListPageApi(this.queryInfo).then((res) => {
        if (res.code === 200) {
          this.lists = res.rows
          this.total = +res.total
          console.log("this.total  ", this.total)
        }
      })
    },
  },

  watch: {
    '$i18n.locale': function() {
      let queryIndex = this.$route.query.class
      this.menuActiveIndex = queryIndex
      if (this.menuActiveIndex === "video") {
      } else {
        
        // this.queryInfo.messageClass = this.menuActiveIndex
        this.queryInfo.messageClass = -1

        this.init()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.zxyhd_container {
  min-height: 100vh;
  background: #f8f8f8;
  .z_top {
    background-color: rgb(161, 186, 240);
    height: 200px;
    background-image: url("../../../assets/images/zxyhdImg/banner@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .z_top1 {
    height: 240px;
    background-image: url("../../../assets/images/zxyhdImg/zxyhd_banner@2x.png");
  }
  .z_main {
    margin: 56px auto;
    min-height: 200px;
    width: 1084px;

    // .z_m-left {
    //   width: 184px;
    //   min-height: 300px;
    //   .z_m_l_title {
    //     color: #1d1d1f;
    //     position: relative;

    //     .z_m_l_t_logo {
    //       position: absolute;
    //       bottom: -32px;
    //       right: 40px;
    //       color: rgba(29, 29, 31, 0.67);
    //       display: block;
    //       font-weight: 100;
    //     }
    //   }
    //   .z_m_l_menus {
    //     margin-top: 71px;
    //     position: relative;
    //     .z_m_l_m_item {
    //       margin-bottom: 32px;
    //     }
    //     .z_m_l_m_i_dot {
    //       width: 12px;
    //       height: 12px;
    //       border-radius: 100%;
    //       background: #dbdbdb;
    //       margin-right: 12px;
    //     }
    //     .z_m_l_m_i_d_yellow {
    //       background: #ff9413;
    //     }
    //     .z_m_l_line {
    //       position: absolute;
    //       right: 40px;
    //       top: 0px;
    //       width: 1px;
    //       height: 200px;
    //       background: #d8d8d8;
    //     }
    //   }
    // }
    .z_m_right {
      width: 900px;
      min-height: 300px;
      padding-bottom: 24px;
      // flex: 1;
      .z_m_r_item {
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 24px;
        margin-bottom: 32px;
        .z_m_r_i_left {
          width: 200px;
          height: 120px;
          margin-right: 18px;
          .z_m_r_i_l_cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .z_m_r_i_center {
          width: 560px;
          text-align: justify;
          margin-right: 40px;
          .z_m_r_i_r_title {
            padding-top: 2px;
          }
          .z_m_r_i_r_p {
            color: rgba(29, 29, 31, 0.8);
            line-height: 24px;
            margin-top: 12px;
            height: 72px;
            overflow: hidden;
            white-space: pre-wrap !important;
          }
        }
        .z_m_r_i_right {
          width: 81px;
          position: relative;

          .z_m_r_i_r_bottom {
            margin-top: 8px;
            color: rgba(29, 29, 31, 0.6);
          }
          .z_m_r_i_line {
            position: absolute;
            top: 32px;
            left: 0;
            width: 1px;
            height: 56px;
            background: #d8d8d8;
          }
        }
      }
      .z_m_r_item_last {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
