<!--
* @description 咨询中心菜单
* @fileName InformationMenu.vue
* @author liulian
* @date 2023/07/25 14:10:37
-->
<template>
  <div class="InformationMenu">
       <div class="z_m-left" :class="{'en': $i18n.locale == 'English'}">
        <!--  @click="clickHandle({key:-1,name:'最新资讯'})" -->
        <div class="z_m_l_title">
          <span class="font-size-32 font-weight-500">{{ $t("information.hd_text1") }}</span>
          <span class="font-weight-500 font-size-24">{{ $t("information.hd_text2") }}</span>
          <span class="z_m_l_t_logo font-size-32">{{ $t("information.hd_text3") }}</span>
        </div>
        <div class="z_m_l_menus">
          <div class="z_m_l_m_item cursor-pointer flex-row align-center" v-for="(item, index) in menus" :key="index" @click="clickHandle(item)">
            <span class="z_m_l_m_i_dot" :class="$route.query.class === item.key+''? 'z_m_l_m_i_d_yellow' : ''"></span>
            <span class="z_m_l_m_i_span font-size-18 font-weight-400 font-color-black-1D1D1F">{{ $t(`information.${item.name}`) }}</span>
          </div>
          <div class="z_m_l_line"></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'InformationMenu',
  components: {},
  props:{
    // 菜单列表
    menuList:{
        type:Array,
        default:() => {
            return []
        }
    }
},
  data() {
    return {
    }
  },
  computed: {},
  created() {
    this.getInit()
},
  mounted() {},
  methods: {
    getInit(){ 
        this.menus= JSON.parse(JSON.stringify(this.menuList))
    },
      /*
     * @Description: 点击侧边栏
     * @MethodAuthor:  liulian
     * @Date: 2023-07-14 18:03:00
     * @param: ''
     */
     clickHandle(item) {
      this.$emit('tab', item);
    },
}
}
</script>

<style scoped lang="scss">
.InformationMenu{
    .z_m-left {
      width: 184px;
      min-height: 300px;
      &.en {
        width: 250px;
      }
      .z_m_l_title {
        color: #1d1d1f;
        position: relative;

        .z_m_l_t_logo {
          position: absolute;
          bottom: -32px;
          right: 40px;
          color: rgba(29, 29, 31, 0.67);
          display: block;
          font-weight: 100;
        }
      }
      .z_m_l_menus {
        margin-top: 71px;
        position: relative;
        .z_m_l_m_item {
          margin-bottom: 32px;
        }
        .z_m_l_m_i_dot {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #dbdbdb;
          margin-right: 12px;
        }
        .z_m_l_m_i_d_yellow {
          background: #ff9413;
        }
        .z_m_l_line {
          position: absolute;
          right: 40px;
          top: 0px;
          width: 1px;
          height: 200px;
          background: #d8d8d8;
        }
      }
    }
}
</style>
